@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

/* General */
/* Hide horizontal scrollbar */
body {
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}
textarea:focus,
select:focus,
input:focus,
button:focus,
.w-input:focus {
  outline: none !important;
  border-color: #d80f10;
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.zoom {
  transition: transform 0.3s; /* Animation */
  cursor: pointer;
}
.zoom:hover {
  transform: scale(1.1) !important;
}
.noHover {
  pointer-events: none;
}

/* General End */
/* Loader */
.loader {
  height: 60vh;
  width: 100%;
  text-align: center;
  padding-top: 20vh;
}
.loader h1,
.loader h3 {
  color: rgba(0, 0, 0, 0.249);
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid rgb(155, 33, 33);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 100px;
    left: 100px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 200px;
    height: 200px;
    opacity: 0;
  }
}
/* Loader End */
/* Nav */
.navbar-user-text {
  transition-duration: 0.2s;
  cursor: pointer;
}
.navbar-user-text:hover {
  color: black !important;
}
.nav-link:visited {
  color: inherit !important;
}
.navbar {
  transition-duration: 0.4s;
  transition-property: background-color;
}

.nav-menu-arrow {
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  /* margin: 5px; */
  /* background-color: red; */
}
.nav-menu-arrow span {
  transform: rotate(0deg);
  transition: all 0.5s ease, top 0.5s ease;
  width: 25px;
  position: absolute;
  height: 1px !important;
  transform: scale(2);
  background-color: black;
  border-radius: 1px;
  left: 0;
}
.nav-menu-arrow span:nth-child(1) {
  top: 0px;
  display: none;
}
.nav-menu-arrow span:nth-child(2) {
  top: 12px;
}
.nav-menu-arrow span:nth-child(3) {
  top: 24px;
}

.nav-menu-arrow-clicked span:nth-child(2) {
  transform: rotate(45deg) scale(2);
  top: 30px;
  width: 15px;
  left: 0px;
}
.nav-menu-arrow-clicked span:nth-child(3),
.nav-menu-arrow:hover span:nth-child(3) {
  transform: rotate(-45deg) scale(2);
  top: 30px;
  width: 15px;
  left: 20px;
}

.navbar-drop {
  transition-property: max-height, padding !important;
  transition-duration: 0.4s !important;
  overflow: hidden !important;
}

.menu-closed {
  max-height: 0 !important;
  padding: 0;
  overflow: hidden !important;
}
.menu-open {
  max-height: 2000px;
}

.nav-link:hover {
  color: black;
}
/* Nav End */

/* Authentication */
.auth {
  width: 100%;
  /* padding: 200px 0; */
  margin-top: 75px;
  margin-bottom: 100px;
  height: 60vh;
}

.auth input {
  font-size: 16px !important;
  border-radius: 200px !important;
  font-family: "Montserrat", sans-serif !important;
  text-align: center;
}
.auth input::placeholder {
  text-transform: lowercase;
  letter-spacing: 2px;
}
.auth input:focus {
  border: transparent !important;
}
.auth-card {
  position: absolute;
  width: 400px;
  padding: 20px;
  transform: translateX(-50%);
  border-radius: 20px;
  text-align: center;
  transition-property: all;
  transition-duration: 0.4s;
  left: 50%;
}
.auth-front {
  background-color: whitesmoke;
  z-index: 2;
}
.auth-back {
  background-color: rgb(220, 220, 220);
  height: 400px;
  transform: translateX(-15%) scale(0.5) rotate(90deg);
  z-index: 1;
}
.auth-back:hover {
  transform: translateX(5%) scale(0.5) rotate(90deg);
}
.auth-back::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background: transparent;
}

.val-error {
  background-color: rgb(255, 182, 182) !important;
}
.auth-form input::placeholder {
  color: #333 !important;
}

@media only screen and (max-width: 500px) {
  .auth-front {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    left: 0;
    transform: none;
  }
  .auth-back {
    height: 400px;
    transform: translateY(-20%) translateX(-50%) scale(0.8);
    z-index: 1;
  }
  .auth-back:hover {
    transform: translateY(-25%) translateX(-50%) scale(0.8);
  }
}
.second-button {
  border: 1px solid #333;
  color: #333;
  border-radius: 200px;
  padding: 11px 16px;
  margin: 0px 3px;
  font-size: 16px;
  letter-spacing: 2px;
  background-color: transparent;
  opacity: 0.5;
  transition-duration: 0.2s;
}
.second-button:hover {
  opacity: 1;
}
.second-button.clicked {
  color: whitesmoke;
  border: 1px solid #333;
  background-color: #333;
  opacity: 1;
}
.second-button.submit {
  color: #d80f10;
  border: 1px solid #d80f10;
  background-color: white;
  opacity: 1;
}
/* Auth End */

/* Landing */
.main-button {
  display: inline-block;
  transition-duration: 0.4s;
}
.main-button:hover {
  background-color: #3f3d56;
  color: #d80f10;
}
.heading-2 {
  font-family: "Montserrat", sans-serif;
}

/* listing item */
.left-arrow,
.right-arrow {
  height: 100px;
}
.list-item-action {
  opacity: 0.1;
  transition-duration: 0.4s;
}
.list-item-action-hovered {
  opacity: 0.5;
}
.list-item-action:hover {
  opacity: 1;
}

/* Listing Page */
.main-map-cont {
  padding: 0 !important;
  height: 100%;
}
#main-map {
  width: 100%;
  height: 100%;
  transition-duration: 0.5s;
}

.map-show {
  height: 60vh !important;
  transition-duration: 0.5s;
  transition-property: height;
}
.map-hide {
  height: 0px !important;
  transition-duration: 0.5s;
  transition-property: height;
}
#map-btn {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 999;
}
.listing-filter {
  margin-top: -50px !important;
  padding-top: 50px !important;
}
.map-cont {
  margin-bottom: 50px;
}
/* .map-show {
  height: 100%;
}
.map-hide {
  height: 200%;
} */
.div-block-17 {
  border-radius: 20px;
  overflow: hidden;
}
.listing-slider {
  height: 100%;
  max-height: 500px;
  background-color: rgb(74, 74, 74);
}
.current-img {
  height: 100%;
}
.solace-button {
  transition-duration: 0.2s;
}
.solace-button-clicked {
  background-color: #d80f10;
}
.email-to {
  color: grey;
}
/* app */
.button3 {
  padding: 9px 15px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.MuiSlider-root {
  color: #333 !important;
}
/* profile */
.errorPage {
  /* height: 60vh; */
  padding: 10vh 0;
  text-align: center;
}
.errorPage img {
  width: 400px;
}

.size-icon::before {
  font-weight: bold;
  content: "ft";
  /* font-size: 18px; */
}
.size-icon::after {
  font-weight: bold;
  content: "2";
  vertical-align: super;
  font-size: 14px;
}

/* CheckSliderMiddle */
.CheckSliderMiddle-cont {
  display: block;
  width: 100%;
  text-align: center;
}
.CheckSliderMiddle div {
  display: inline-block;
  width: 40px;
  border-radius: 200px;
  margin: 5px 10px 0 10px;
  /* height: 20px; */
  background-color: #333;
  cursor: pointer;
  color: whitesmoke;
  padding: 10px;
  font-size: 18px;
}
.CheckSliderMiddle div.selected.left {
  background-color: #d80f10;
}
.CheckSliderMiddle div.selected.right {
  background-color: rgba(28, 206, 108, 0.817);
}
.CheckSliderMiddle div.selected {
  background-color: rgb(169, 169, 169);
}
input[type="checkbox"] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.2); /* IE */
  -moz-transform: scale(1.2); /* FF */
  -webkit-transform: scale(1.2); /* Safari and Chrome */
  -o-transform: scale(1.2); /* Opera */
  transform: scale(1.2);
  /* padding: 10px; */
}
.form-check-input:checked {
  background-color: #d80f10;
  border-color: #d80f10;
}
.form-check-input:focus {
  border-color: rgb(169, 169, 169);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(142, 142, 142, 0);
}
.form-block-hide {
  max-height: 0px;
  overflow: hidden;
  transition-duration: 0.4s;
  transition-property: max-height;
}
.form-block-show {
  max-height: 10000px;
  /* overflow: hidden; */
  transition-duration: 0.4s;
  transition-property: max-height;
}
.form-sub-title {
  cursor: pointer;
  margin-bottom: 20px;
}
.form-heading {
  transition-duration: 0.5s;
  margin-bottom: 0;
}
.form-sub-title:hover .form-heading {
  color: black !important;
}
.form-map {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Dropdowns */
.dropbtn {
  background-color: white;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.dropdown-content {
  display: none;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
  position: absolute;
  width: 100%;
  text-align: center;
}
.dropdown-content div {
  color: black;
  padding: 12px 16px;
}
.dropdown-content div:hover {
  background-color: #f1f1f1;
  border-radius: inherit;
}
.dropdown:hover .dropdown-content {
  display: block;
  border-radius: 20px !important;
}

.json-input input {
  background-color: white;
  box-shadow: 5px 5px 20px -11px #000;
  /* margin: 5px 0; */
  width: 100%;
  outline: none;
}

.json-input button {
  font-size: 18px;
  margin: 0 3px;
}
input[type="file"] {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  font-weight: normal;
}
.list-item-action .html-embed-2 {
  font-size: inherit !important;
}
.dropdown::after {
  content: "\25be";
  /* float: right; */
  position: absolute;
  right: 10px;
}
/* Set the size of the div element that contains the map */
#map {
  height: 400px;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
}
.w-input.error,
.ui.toggle.checkbox input ~ .error:before {
  background-color: rgb(255, 202, 202) !important;
}
#message {
  height: auto;
  min-height: 200px;
}
.ui.toggle.checkbox input:checked ~ label:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: #333 !important;
}
.pw-page {
  padding-top: 10vh;
  min-height: 55vh;
}
.contact-page {
  min-height: 60vh;
}
.register-confirmed {
  margin: 100px auto !important;
  width: 200px;
  font-size: 20px;
}
.auth-msg {
  color: #d80f10;
}
.button-2 {
  border-radius: 10px;
  transition-duration: 0.3s;
  color: whitesmoke;
}
.button-2:hover {
  color: whitesmoke;
  background-color: black;
}
.list-item {
  cursor: pointer;
}
.json-btn {
  background-color: transparent;
  color: #333;
  border-radius: 10px;
  border: 1px solid #333;
  transition-duration: 0.2s;
}
.json-btn:hover {
  color: black;
  border: 1px solid black;
}

/* Check sliders */

.slider-checkbox {
  position: relative;
  margin: 8px 0;
}
.slider-checkbox input {
  margin: 0px;
  margin-top: 1px;
  cursor: pointer;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  background: red;
  width: 40px;
  height: 20px;
}
.slider-checkbox input:checked + .label:before {
  background-color: #d80f10;
  padding-left: 6px;
}
.slider-checkbox input:checked + .label:after {
  left: 21px;
}
.slider-checkbox .label {
  position: relative;
  padding-left: 46px;
}
.slider-checkbox .label:before,
.slider-checkbox .label:after {
  position: absolute;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  transition: background-color 0.3s, left 0.3s;
}
.slider-checkbox .label:before {
  content: "";
  color: #fff;
  box-sizing: border-box;
  padding-left: 23px;
  font-size: 12px;
  line-height: 20px;
  background-color: #888;
  left: 0px;
  top: 0px;
  height: 20px;
  width: 40px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.slider-checkbox .label:after {
  content: "";
  letter-spacing: 20px;
  background: #fff;
  left: 1px;
  top: 1px;
  height: 18px;
  width: 18px;
}
.listing-item {
  max-width: 400px;
  margin: 0 auto;
}

.noresults {
  text-align: center;
  margin: 150px 0;
}
.cards-image-mask.smaller {
  width: 60%;
  margin: 0 auto;
}
.pagination {
  text-align: center;
  width: 100%;
  display: unset;
  font-weight: bold;
}
.pagination button {
  font-size: 18px;
  background-color: transparent;
  color: #333;
  padding: 20px;
}
.pagination button.selected {
  color: #d80f10;
  font-size: 20px;
}
.image-5,
.image-4 {
  border-radius: 10px;
}
.anyUser {
  margin: 20vh 0;
}
