@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* .slide img {
  width: 20rem;
  margin: 0 auto;
} */

.slide {
  transform: scale(0.4);
  transition: transform 300ms;
  opacity: 0.5;
}

.activeSlide {
  transform: scale(1);
  opacity: 1;
}

.arrow {
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow i {
  transition: color 300ms;
  font-size: 40px;
}
.arrow i:hover {
  color: whitesmoke;
}
.next {
  right: 0%;
  top: 50%;
}
.prev {
  left: 0%;
  top: 50%;
}
.small-carousel {
  margin: 0 10% !important;
  width: 80% !important;
}
