.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: " ";
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: " ";
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.contact-form {
  padding-top: 100px;
  padding-bottom: 100px;
}

.contact-form-grid {
  display: -ms-grid;
  display: grid;
  margin-top: 40px;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block {
  padding-top: 13px;
  padding-bottom: 13px;
}

.div-block-2 {
  padding-top: 19px;
  padding-bottom: 19px;
}

.form-block {
  margin-top: 148px;
}

.nav-link {
  padding-right: 30px;
  padding-left: 30px;
  color: rgba(51, 51, 51, 0.8);
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.grid {
  grid-row-gap: 59px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto;
}

.image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.lightbox-link {
  width: 100%;
  height: 100%;
}

.slider {
  height: 100%;
  min-height: 300px;
  border-radius: 20px;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.navbar-top {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.navbar {
  padding: 15px 40px;
}

.navbar.shadow {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #fff;
}

.logo-main {
  height: 75px;
  max-width: none;
}

.profile-icon {
  height: 60px;
  max-width: none;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-drop {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.shadow {
  margin-right: 0px;
  margin-left: 0px;
  box-shadow: 0 7px 20px -12px rgba(0, 0, 0, 0.44);
}

.navbar-user-text {
  padding-right: 10px;
  padding-left: 10px;
  color: rgba(51, 51, 51, 0.8);
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.footer {
  margin-top: 50px;
}

.image-2 {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.wave1 {
  width: 100%;
  height: 200px;
}

.heading {
  margin-top: 0px;
  margin-bottom: 0px;
}

.div-block-5 {
  width: 100%;
  height: 150px;
  margin-top: -60px;
  background-image: url("../images/FooterWave1.png");
  background-position: 0px 0px;
  background-size: 100% 100%;
}

.footer-logo {
  width: 175px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.grid-2 {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.foorer-logo-main {
  height: 150px;
}

.nav-link-footer {
  padding-right: 30px;
  padding-left: 30px;
  color: #2e2e2e;
  text-transform: lowercase;
}

.div-block-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-family: Montserrat, sans-serif;
}

.div-block-9.app {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.listing-icon {
  color: #d80f10;
  font-size: 24px;
}

.listing-icon.black {
  padding-right: 3px;
  color: #2e2e2e;
}

.listing-icon.profile {
  width: 50px;
}

.listing-text {
  margin-bottom: -2px;
  padding-left: 3px;
  color: #555;
  font-size: 16px;
  letter-spacing: 1.2px;
  text-transform: lowercase;
}

.listing-text.profile {
  text-transform: none;
}

.listing-text.checklist {
  margin: 20px 0px 9px;
  color: #d80f10;
}

.listing-value {
  margin-bottom: 3px;
  padding-right: 5px;
  color: #000;
  font-size: 28px;
  font-weight: 400;
}

.div-block-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-family: Montserrat, sans-serif;
}

.price-value {
  color: #d80f10;
  font-size: 30px;
}

.address {
  margin-top: 5px;
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  font-weight: 600;
}

.div-block-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.grid-3 {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.text-block {
  margin-bottom: -7px;
}

.div-block-12 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.div-block-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 25px;
}

.div-block-14 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.mask {
  border-radius: 20px;
}

.description {
  margin-top: 18px;
  font-family: Montserrat, sans-serif;
  color: #646464;
}

.grid-4 {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.solace-button {
  padding: 12px 20px;
  border-radius: 300px;
  background-color: #464646;
  color: #eee;
  font-size: 26px;
  text-align: center;
}

.text-block-2 {
  font-family: Montserrat, sans-serif;
  font-size: 22px;
}

.text-block-3 {
  font-family: Montserrat, sans-serif;
  color: #d80f10;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1.2px;
  text-transform: lowercase;
}

.text-block-3.profile {
  text-align: left;
}

.text-block-3.edit {
  text-align: left;
}

.grid-5 {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.listing-conditionals {
  padding: 25px;
  border-radius: 20px;
  background-color: rgba(28, 206, 109, 0.36);
}

.listing-conditionals.red {
  margin-top: 15px;
  background-color: rgba(216, 15, 16, 0.28);
}

.listing-conditionals.shadow2 {
  min-height: 25%;
}

.div-block-16 {
  background-color: rgba(216, 15, 16, 0.28);
}

.check {
  width: 100%;
  padding-bottom: 15px;
  color: #1cce6d;
  font-size: 24px;
  text-align: center;
}

.check.red {
  color: #d80f10;
}

.map {
  height: 100%;
  border-radius: 20px;
}

.map.shadow2 {
  background-image: url("../images/Screen-Shot-2021-10-01-at-7.12.13-PM.png");
  background-position: 0px 0px;
  background-size: auto;
  box-shadow: 4px 4px 20px -12px #000;
}

.shadow2 {
  padding: 23px;
  border-radius: 20px;
  box-shadow: 5px 5px 20px -11px #000;
}

.shadow2.div-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.shadow2.profile {
  margin-top: 20px;
  padding-right: 40px;
  padding-left: 40px;
  background-color: #fff;
}

.div-block-17 {
  min-height: 400px;
}

.utility {
  padding-left: 0px;
}

.div-block-18 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid-6 {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.div-block-21 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-22 {
  height: 250px;
  background-image: url("../images/wave-5.png");
  background-position: 0px 0px;
  background-size: 100% 100%;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.div-block-23 {
  height: 160px;
  background-image: url("../images/wave-4.png");
  background-position: 0px 0px;
  background-size: 100% 100%;
}

.div-block-24 {
  background-color: #535353;
}

.hero-illustration {
  width: 100%;
}

.grid-7 {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.main-button {
  margin-bottom: 30px;
  padding: 9px 25px;
  border-style: solid;
  border-width: 1px;
  border-color: #d80f10;
  border-radius: 300px;
  background-color: transparent;
  font-family: Montserrat, sans-serif;
  color: #d80f10;
  font-size: 28px;
  font-style: normal;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.hero-div {
  text-align: center;
}

.grid-8 {
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.listing-item.shadow2 {
  padding: 0px;
}

.grid-9 {
  padding-right: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 2.75fr 1fr;
  grid-template-columns: 2.75fr 1fr;
  border-radius: 0px 0px 20px 20px;
  background-color: #333;
}

.slider-2 {
  border-radius: 20px;
}

.image-3 {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.mask-2 {
  border-radius: 20px 20px 0px 0px;
}

.div-block-25 {
  position: absolute;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 19px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(75%, transparent), to(#333));
  background-image: linear-gradient(180deg, transparent 75%, #333);
  opacity: 1;
  color: #fff;
}

.text-block-4 {
  color: #f5f5f5;
}

.icon {
  padding-top: 30px;
  color: #f5f5f5;
}

.icon-2 {
  padding-top: 30px;
  color: #f5f5f5;
}

.slide-nav {
  color: #f5f5f5;
}

.body {
  font-family: Montserrat, sans-serif;
}

.text-block-5 {
  margin-top: 13px;
  margin-bottom: 7px;
  color: #d80f10;
  font-size: 32px;
}

.text-block-5.dollarsign {
  font-size: 22px;
}

.text-block-6 {
  padding-bottom: 6px;
  color: #f8f8f8;
  font-size: 22px;
  font-weight: 600;
  white-space: nowrap;
}

.text-block-7 {
  color: #f5f5f5;
  font-size: 16px;
  font-weight: 600;
  text-align: right;
}

.div-block-26 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.div-block-26.lower {
  padding-bottom: 9px;
}

.html-embed-2 {
  padding-left: 8px;
  color: #f5f5f5;
  font-size: 20px;
  font-weight: 300;
}

.div-block-27 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.list-item-action {
  font-size: 30px;
}

.right-arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.left-arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.gallery {
  padding-top: 0px;
  padding-bottom: 100px;
}

.centered-container {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.gallery-grid-container {
  display: -ms-grid;
  display: grid;
  margin-top: 40px;
  -ms-grid-row-align: center;
  align-self: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-areas: ".";
  -ms-grid-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: 1fr 20px 1fr;
  grid-template-rows: 1fr 1fr;
}

.gallery-lightbox {
  position: relative;
  overflow: hidden;
}

.gallery-thumbnail {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.heading-2 {
  text-transform: capitalize;
}

.text-span {
  color: #d80f10;
}

.image-4 {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.image-5 {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.grid-10 {
  grid-column-gap: 50px;
  -ms-grid-columns: 1fr 0.75fr;
  grid-template-columns: 1fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-28 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.div-block-28.form {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.div-block-29 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.body-2 {
  background-color: #f5f5f5;
  font-family: Montserrat, sans-serif;
}

.paragraph {
  font-size: 16px;
  line-height: 25px;
}

.heading-3 {
  margin-top: 5px;
}

.button3 {
  padding-right: 20px;
  padding-left: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #d80f10;
  border-radius: 300px;
  background-color: #fff;
  color: #d80f10;
  letter-spacing: 1.2px;
  text-transform: lowercase;
}

.button3.grey {
  border-color: #333;
  color: #333;
}

.button3.listing-button {
  display: block;
  width: 200px;
  margin-top: 49px;
  margin-right: auto;
  margin-left: auto;
}

.grid-11 {
  -ms-grid-columns: 0.75fr 1fr;
  grid-template-columns: 0.75fr 1fr;
}

.heading-4 {
  letter-spacing: 1.2px;
}

.heading-5 {
  color: #242424;
  letter-spacing: 1.2px;
}

.text-span-2 {
  color: #f5f5f5;
}

.cards-section {
  padding-top: 49px;
  padding-bottom: 51px;
}

.cards-grid-container {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  text-align: left;
}

.cards-image-mask {
  position: relative;
  width: 100%;
  padding-top: 60%;
  border-radius: 0%;
}

.cards-image {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  max-width: none;
  padding-right: 89px;
  padding-left: 89px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.div-block-30 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #727272;
}

.div-block-31 {
  text-align: center;
}

.div-block-31.shadow2 {
  background-color: #fff;
}

.paragraph-2 {
  margin-bottom: 15px;
  font-size: 16px;
}

.text-block-8 {
  margin-left: auto;
}

.text-block-9 {
  width: 50%;
  margin-right: auto;
  margin-left: auto;
}

.grid-12 {
  padding-right: 60px;
  padding-bottom: 50px;
  padding-left: 60px;
  grid-row-gap: 51px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.filter-actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.range-slider {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.range-input {
  margin-bottom: 0px;
  border-radius: 300px;
  font-size: 16px;
  text-align: center;
}

.grid-13 {
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.yes-no-maybe {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.checklist {
  margin-right: auto;
  margin-left: auto;
}

.checkbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.body-3 {
  background-color: #f5f5f5;
  font-family: Montserrat, sans-serif;
}

.form-heading {
  color: #555;
  font-size: 22px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.grid-14 {
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-14.contact {
  margin-top: 55px;
}

.image-7 {
  width: 75%;
}

.grid-15 {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.map-2 {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 5px 5px 20px -11px #000;
}

.div-block-32 {
  height: 200px;
}

.text-field {
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 5px 5px 20px -11px #000;
  letter-spacing: 1px;
  text-transform: lowercase;
}

.text-field.text-input {
  min-height: 120px;
}

.text-field.shadow2 {
  box-shadow: 5px 5px 20px -11px #000;
}

.form-sub-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 79px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-button {
  font-size: 32px;
}

.div-block-33 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 10px;
}

.div-block-33.shadow2 {
  overflow: hidden;
  padding: 0px;
}

.button {
  background-color: #d80f10;
  color: #f5f5f5;
  text-align: center;
}

.grid-16 {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.image-8 {
  width: 100%;
  height: 200px;
  -o-object-fit: cover;
  object-fit: cover;
}

.button-2 {
  width: 100%;
  border-radius: 10px;
  font-size: 16px;
}

.button-2.shadow2 {
  padding: 9px;
  background-color: #333;
  text-align: center;
}

.list-item {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 22px;
  padding-bottom: 22px;
  border-radius: 10px;
  background-color: #fff;
  font-size: 18px;
  text-align: center;
  letter-spacing: 2px;
}

.heading-6 {
  color: #d80f10;
}

.div-block-34 {
  width: 100%;
}

.grid-17 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 0.75fr;
  grid-template-columns: 1fr 1fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.button-3 {
  margin-right: 5px;
  margin-left: 5px;
  border: 1px solid #000;
  border-radius: 15px;
  background-color: transparent;
  color: #000;
  font-size: 22px;
}

.div-block-35 {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: center;
}

.div-block-36 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left;
}

@media screen and (max-width: 991px) {
  .navbar.shadow {
    z-index: 999;
  }

  .navbar-user-text {
    display: none;
  }

  .listing-icon {
    font-size: 22px;
  }

  .listing-text {
    font-size: 14px;
  }

  .listing-value {
    font-size: 26px;
  }

  .address {
    font-size: 28px;
  }

  .text-block-2 {
    font-size: 18px;
  }

  .grid-8 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .gallery-grid-container {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .cards-grid-container {
    grid-column-gap: 20px;
  }

  .grid-12 {
    grid-row-gap: 20px;
  }

  .grid-16 {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .contact-form {
    padding: 40px 20px;
  }

  .nav-link {
    padding-right: 0px;
    padding-left: 0px;
  }

  .grid {
    grid-row-gap: 21px;
  }

  .footer-logo {
    width: 125px;
  }

  .div-block-6 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .div-block-7 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .div-block-8 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .foorer-logo-main {
    height: 125px;
  }

  .nav-link-footer {
    font-size: 14px;
  }

  .div-block-12 {
    padding-left: 36px;
  }

  .text-block-3.special-3 {
    display: none;
  }

  .container {
    padding-right: 10px;
    padding-left: 10px;
  }

  .grid-7 {
    grid-row-gap: 58px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .main-button.shadow {
    margin-bottom: 10px;
  }

  .gallery {
    padding: 40px 20px;
  }

  .gallery-grid-container {
    max-width: none;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }

  .container-2 {
    padding-right: 101px;
    padding-left: 101px;
  }

  .grid-10 {
    grid-column-gap: 15px;
    -ms-grid-columns: 0.75fr 0.75fr;
    grid-template-columns: 0.75fr 0.75fr;
  }

  .heading-3 {
    font-size: 32px;
    line-height: 32px;
  }

  .cards-section {
    padding: 40px 20px;
  }

  .cards-grid-container {
    max-width: none;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .image-6 {
    padding-right: 15px;
    padding-left: 15px;
  }

  .container-4 {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media screen and (max-width: 479px) {
  .nav-link {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
  }

  .slider {
    width: 100%;
    height: auto;
    min-height: 0px;
  }

  .navbar.shadow {
    padding-right: 10px;
    padding-left: 10px;
  }

  .logo-main {
    height: 50px;
  }

  .profile-icon {
    height: 50px;
  }

  .navbar-drop {
    height: 90%;
    padding-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .div-block-4 {
    margin-bottom: -60px;
  }

  .div-block-5 {
    margin-top: 0px;
    background-size: 300% 100%;
  }

  .footer-logo {
    width: 40vw;
    margin-right: 10px;
    margin-left: 10px;
    padding-top: 25px;
  }

  .div-block-6 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .div-block-8 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .foorer-logo-main {
    height: 125px;
  }

  .nav-link-footer {
    width: 100%;
    padding-top: 13px;
    text-align: center;
  }

  .grid-3 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .grid-5 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .shadow2.profile {
    padding-right: 13px;
    padding-left: 13px;
  }

  .div-block-22 {
    height: 150px;
  }

  .div-block-23 {
    height: 150px;
  }

  .grid-8 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .centered-container {
    text-align: left;
  }

  .gallery-grid-container {
    margin-right: 0px;
    margin-left: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto;
  }

  .container-2 {
    padding-right: 9px;
    padding-left: 9px;
  }

  .div-block-28 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .body-2 {
    background-color: #f5f5f5;
  }

  .button3 {
    padding-right: 10px;
    padding-left: 10px;
  }

  .heading-5 {
    font-size: 32px;
    line-height: 32px;
  }

  .cards-grid-container {
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    grid-row-gap: 20px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .cards-image {
    padding-right: 0px;
    padding-left: 0px;
  }

  .div-block-31.shadow2 {
    background-color: #fff;
  }

  .container-3 {
    padding-right: 10px;
    padding-left: 10px;
  }

  .grid-12 {
    padding-right: 10px;
    padding-left: 10px;
  }

  .range-input {
    border-style: solid;
    border-width: 1px;
    border-color: #333;
  }

  .grid-14 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-16 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .grid-17 {
    -ms-grid-columns: 1fr 1fr 1.25fr;
    grid-template-columns: 1fr 1fr 1.25fr;
  }
}

#w-node-_4818eeae-96ef-f943-8f3b-8f68e641c944-678a698d {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_0d2eed62-1b10-2378-235d-408e6a238a2f-678a698d {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_9f012e97-d64a-9291-1569-4e99e24d2b5e-296caa15 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_5c9b2f0c-7c28-6c42-9800-7bde58294640-296caa15 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_154a121b-6f1d-ff6b-5b79-9a1f1fc8607e-296caa15 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-f8d9e038-d363-5516-4da1-886a914efe44-296caa15 {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-a63548a9-822d-47fb-d726-ce3e31096351-296caa15 {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_20ee37f1-3eb3-3170-1b8b-f53a8de55a17-296caa15 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-a27b4229-8099-7087-f90e-d484a8da931f-296caa15 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-efae3d37-ed15-b27f-d96f-b52556a812a7-296caa15 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_2c176fbf-27c5-2e69-db56-8942350eb565-296caa15 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_3334a043-4cd4-8053-8d3c-8bea2d50ba8e-296caa15 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_599287c3-f9ba-8002-c314-a9057ca0b297-296caa15 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_167063ef-27eb-bdfe-3315-433efbb4aeac-296caa15 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-d6c7e5ea-2acb-6f7b-6558-956e6bc05aba-885d22ed {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_72db3371-08ed-3ede-8c10-6c2962569413-885d22ed {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-e31f7ef7-8604-6919-2f56-ea9bc9f774bc-885d22ed {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_31edd9f9-9096-4fbd-cc1f-e45a670e871c-885d22ed {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-bd957099-76a2-af3d-7df6-5b77dfc9f4eb-885d22ed {
  -ms-grid-row: span 2;
  grid-row-start: span 2;
  -ms-grid-row-span: 2;
  grid-row-end: span 2;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_0ed46d85-4eb7-d5cd-d531-3575b328435b-65674ccc {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_22b1ae74-1765-ae24-42fe-fc479750e585-65674ccc {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_22b1ae74-1765-ae24-42fe-fc479750e589-65674ccc {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_22b1ae74-1765-ae24-42fe-fc479750e58b-65674ccc {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_6c9242c6-f86b-af38-7f55-2df3a7eb8834-8cdfb1a7 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-ff3ce9bc-2f22-157c-038e-9f2c5ceb074a-8cdfb1a7 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-b38d9674-dfcf-83e6-5d45-eb0f3b4740ea-8cdfb1a7 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_0a6b8d55-b57f-363b-bb25-2bbfe026536c-8cdfb1a7 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_069aad62-8b12-cc0a-12fc-d8f4b1a818f0-8cdfb1a7 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_0e89eb99-e483-0b79-2c53-f9533c8a519e-8cdfb1a7 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_0e89eb99-e483-0b79-2c53-f9533c8a51ac-8cdfb1a7 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_85b389fa-ac5e-e991-666a-add8205fa876-8cdfb1a7 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_58df4415-c17e-6386-4959-4313e968e8e9-be368e20 {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#address.w-node-b7b62d2f-47af-fb4d-60d5-c9ddebfb86a7-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_04242738-04bc-69d4-6b08-3be51e0ad822-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-f4538895-c086-083c-ffe4-4353f2e5f132-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 6;
  grid-column-start: span 6;
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
}

#w-node-_806ca343-57bd-c792-4ae3-afd7f2c3f968-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 6;
  grid-column-start: span 6;
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
}

#phone.w-node-f2016ec3-724c-ea63-a932-ba13d006002a-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#email-6.w-node-c44e90da-fb8f-b03e-1e18-127cbea5767f-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#description.w-node-_3e8829d7-dc69-7d01-fec8-1ad5a6b61cca-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 6;
  grid-column-start: span 6;
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
}

#beds.w-node-bb6437c1-f634-5d10-37e0-f4614208813d-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#baths.w-node-_205a556d-530f-af68-48ed-2eb9b658b760-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#size.w-node-_3e1d7437-ceb6-b80b-57b0-25590dc52e1f-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#price.w-node-_9ad88335-b3a7-fe83-bfbd-2c3dd8614242-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#lease-length.w-node-_72ebd21f-8057-17ec-6020-f52a8fc7e528-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_4b2bfbed-d7dd-1b03-f096-ce146567841f-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-e02a8b3d-e984-8936-e13e-6badb4c9977f-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-cea29643-f9da-729d-5747-e977ebd1d05b-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#parking-spots.w-node-_5ed44f57-ac3f-d347-3647-de895c2fb55e-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#w-node-c0b282df-fb5b-0f29-e71b-4c096d142682-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#outdoor-spaces.w-node-_4c535d94-59e7-d2ec-365d-b7ae6caed52a-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#outdoor-spaces-2.w-node-_3943af81-bd10-f525-5554-2feb2bbd61cb-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#outdoor-spaces-3.w-node-b7353bf5-c7de-bcdd-6b82-05e5d602c4d6-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_37b73e85-e110-86f6-f77f-4121e45a29e6-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_1f9f96c6-b7c0-d49e-13f9-14365f464963-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_44218a53-5606-e830-1d63-a97433823e60-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_53007b88-a6a8-4746-5f07-1093b2d23a31-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_3014b9f0-fbdd-f737-330d-dcdd771fd10b-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-ee61ef04-73ae-e7b8-85f4-d024d6af160e-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_8df87a18-c5b7-a2c8-0a9c-662be69c294f-be368e20 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 4;
  grid-column-start: span 4;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
}

#w-node-_5af7106d-c94b-d07e-67d6-47cac4577985-18dc9456 {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#fname.w-node-_5af7106d-c94b-d07e-67d6-47cac457798e-18dc9456 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#lname.w-node-_5af7106d-c94b-d07e-67d6-47cac457798f-18dc9456 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#phone.w-node-_5af7106d-c94b-d07e-67d6-47cac4577990-18dc9456 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#bio.w-node-_5af7106d-c94b-d07e-67d6-47cac4577993-18dc9456 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 6;
  grid-column-start: span 6;
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
}

#email.w-node-fe6a549d-86d0-1750-3af9-b21887c8bad9-18dc9456 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 6;
  grid-column-start: span 6;
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
}

#w-node-_16bfb26c-c7ab-640f-13a0-a737e1c5ffe0-56c17883 {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#Name-3.w-node-_16bfb26c-c7ab-640f-13a0-a737e1c5ffe9-56c17883 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#email-8.w-node-_16bfb26c-c7ab-640f-13a0-a737e1c5ffea-56c17883 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
}

#message.w-node-_16bfb26c-c7ab-640f-13a0-a737e1c5ffee-56c17883 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 6;
  grid-column-start: span 6;
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
}

@media screen and (max-width: 991px) {
  #w-node-_386770a4-0249-0999-88cb-0d17559c1958-8cdfb1a7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-b38d9674-dfcf-83e6-5d45-eb0f3b4740e1-8cdfb1a7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_0a6b8d55-b57f-363b-bb25-2bbfe0265363-8cdfb1a7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_069aad62-8b12-cc0a-12fc-d8f4b1a818e7-8cdfb1a7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_0e89eb99-e483-0b79-2c53-f9533c8a5195-8cdfb1a7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_0e89eb99-e483-0b79-2c53-f9533c8a51a3-8cdfb1a7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_757d57fe-dfb7-e160-41ad-4c1021938e7a-8cdfb1a7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_26f91d16-3848-94fd-32ca-d5608763e73a-8cdfb1a7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-cbbdfc56-c2e0-83a9-2880-400ec99436ed-8cdfb1a7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #parking-spots.w-node-_5ed44f57-ac3f-d347-3647-de895c2fb55e-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 6;
    grid-column-start: span 6;
    -ms-grid-column-span: 6;
    grid-column-end: span 6;
  }

  #w-node-c0b282df-fb5b-0f29-e71b-4c096d142682-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 6;
    grid-column-start: span 6;
    -ms-grid-column-span: 6;
    grid-column-end: span 6;
  }

  #w-node-_8df87a18-c5b7-a2c8-0a9c-662be69c294f-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_9f012e97-d64a-9291-1569-4e99e24d2b5e-296caa15 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_5c9b2f0c-7c28-6c42-9800-7bde58294640-296caa15 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_20ee37f1-3eb3-3170-1b8b-f53a8de55a17-296caa15 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-a27b4229-8099-7087-f90e-d484a8da931f-296caa15 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_599287c3-f9ba-8002-c314-a9057ca0b297-296caa15 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_167063ef-27eb-bdfe-3315-433efbb4aeac-296caa15 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-f8b90900-0314-a097-9194-bc575d3ddf21-65674ccc {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_87fdf5d4-1135-7983-089f-a0932af7a5be-65674ccc {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_7b2de6f2-759b-0ff8-82c5-aad8f3189034-65674ccc {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_7b2de6f2-759b-0ff8-82c5-aad8f3189036-65674ccc {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_227822fa-a0f5-aada-eaa8-b34c2596cbe4-f1ab5453 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-f39fd9af-af72-a3de-7f46-55e82dd6abfd-f1ab5453 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_832aec8c-5a4e-158e-683f-58d13a3dd5b5-f1ab5453 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_5c9b2f0c-7c28-6c42-9800-7bde58294640-296caa15 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-f8d9e038-d363-5516-4da1-886a914efe44-296caa15 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row-align: end;
    align-self: end;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_76e5fcdc-487b-e4b7-c564-f36e20b5176c-296caa15 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-a63548a9-822d-47fb-d726-ce3e31096351-296caa15 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row-align: end;
    align-self: end;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-efae3d37-ed15-b27f-d96f-b52556a812a7-296caa15 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_2c176fbf-27c5-2e69-db56-8942350eb565-296caa15 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_3334a043-4cd4-8053-8d3c-8bea2d50ba8e-296caa15 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_599287c3-f9ba-8002-c314-a9057ca0b297-296caa15 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_167063ef-27eb-bdfe-3315-433efbb4aeac-296caa15 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_4fb76d82-3792-529b-f8c1-0d98dc935a2b-65674ccc {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-b03b4af7-8e1e-8ce6-983e-93edbac8e293-65674ccc {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_7b2de6f2-759b-0ff8-82c5-aad8f318902a-65674ccc {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_7b2de6f2-759b-0ff8-82c5-aad8f3189031-65674ccc {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_386770a4-0249-0999-88cb-0d17559c1958-8cdfb1a7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-b38d9674-dfcf-83e6-5d45-eb0f3b4740e1-8cdfb1a7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_0a6b8d55-b57f-363b-bb25-2bbfe0265363-8cdfb1a7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_069aad62-8b12-cc0a-12fc-d8f4b1a818e7-8cdfb1a7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_0e89eb99-e483-0b79-2c53-f9533c8a5195-8cdfb1a7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_0e89eb99-e483-0b79-2c53-f9533c8a51a3-8cdfb1a7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_757d57fe-dfb7-e160-41ad-4c1021938e7a-8cdfb1a7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
  }

  #w-node-_85b389fa-ac5e-e991-666a-add8205fa876-8cdfb1a7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-e8fc0894-9c1a-960a-9343-c1b61f60ff80-8cdfb1a7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-de352948-ded4-94a7-5d60-75d9b08c6c38-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_58df4415-c17e-6386-4959-4313e968e8e9-be368e20 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #address.w-node-b7b62d2f-47af-fb4d-60d5-c9ddebfb86a7-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_04242738-04bc-69d4-6b08-3be51e0ad822-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-f4538895-c086-083c-ffe4-4353f2e5f132-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_806ca343-57bd-c792-4ae3-afd7f2c3f968-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #phone.w-node-f2016ec3-724c-ea63-a932-ba13d006002a-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #email-6.w-node-c44e90da-fb8f-b03e-1e18-127cbea5767f-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #description.w-node-_3e8829d7-dc69-7d01-fec8-1ad5a6b61cca-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #beds.w-node-bb6437c1-f634-5d10-37e0-f4614208813d-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #baths.w-node-_205a556d-530f-af68-48ed-2eb9b658b760-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #size.w-node-_3e1d7437-ceb6-b80b-57b0-25590dc52e1f-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #price.w-node-_9ad88335-b3a7-fe83-bfbd-2c3dd8614242-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #lease-length.w-node-_72ebd21f-8057-17ec-6020-f52a8fc7e528-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_4b2bfbed-d7dd-1b03-f096-ce146567841f-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-e02a8b3d-e984-8936-e13e-6badb4c9977f-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-cea29643-f9da-729d-5747-e977ebd1d05b-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #parking-spots.w-node-_5ed44f57-ac3f-d347-3647-de895c2fb55e-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-c0b282df-fb5b-0f29-e71b-4c096d142682-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #outdoor-spaces-3.w-node-b7353bf5-c7de-bcdd-6b82-05e5d602c4d6-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-b76bfb92-4b2a-e0d9-0dcb-b79896462491-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_8df87a18-c5b7-a2c8-0a9c-662be69c294f-be368e20 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5af7106d-c94b-d07e-67d6-47cac457797c-18dc9456 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_5af7106d-c94b-d07e-67d6-47cac4577985-18dc9456 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #fname.w-node-_5af7106d-c94b-d07e-67d6-47cac457798e-18dc9456 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #lname.w-node-_5af7106d-c94b-d07e-67d6-47cac457798f-18dc9456 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #phone.w-node-_5af7106d-c94b-d07e-67d6-47cac4577990-18dc9456 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #bio.w-node-_5af7106d-c94b-d07e-67d6-47cac4577993-18dc9456 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #email.w-node-fe6a549d-86d0-1750-3af9-b21887c8bad9-18dc9456 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_16bfb26c-c7ab-640f-13a0-a737e1c5ffd7-56c17883 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_16bfb26c-c7ab-640f-13a0-a737e1c5ffe0-56c17883 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #Name-3.w-node-_16bfb26c-c7ab-640f-13a0-a737e1c5ffe9-56c17883 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #email-8.w-node-_16bfb26c-c7ab-640f-13a0-a737e1c5ffea-56c17883 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #message.w-node-_16bfb26c-c7ab-640f-13a0-a737e1c5ffee-56c17883 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
}
